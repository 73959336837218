import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, mapProps } from '@shakacode/recompose';
import { useQuery } from '~/lazy_apollo/client';

import { getParam } from '../../utils/urls';
import { withRestaurant } from '../../utils/withRestaurant';
import { classNames } from '../../utils/withStyles';
import menuItemQuery from '../../libs/gql/queries/menus/menuItemQuery.gql';

import LoadingPage from '../shared/LoadingPage';
import MenuItemDetails from './MenuItemDetails';
import Meta from '../shared/Meta';
import NotFoundContainer from '../shared/NotFoundContainer';

const MenuItemPage = (props) => {
  const { loading, data } = useQuery(menuItemQuery, {
    variables: {
      locationSlug: getParam('location', props.location.search), // location=slug QS
      menuSlug: getParam('menu', props.location.search), // menu=slug QS
      restaurantId: props.restaurantId,
      slug: props.match.params.dishSlug, // /items/slug route
    },
  });

  const renderMeta = () => {
    const { menuItem } = data;
    const { menu } = menuItem.section;
    const { location } = menu;
    let description = `${menuItem.name} at ${menuItem.restaurant.name} in ${location.city}, ${location.state}. View photos, read reviews, and see ratings for ${menuItem.name}.`;
    if (menuItem.description) {
      description += ` ${menuItem.description}`;
    }
    let imageUrl = null;
    let imageWidth = null;
    let imageHeight = null;
    if (menuItem.photos[0] && menuItem.photos[0].shareUrl) {
      imageUrl = menuItem.photos[0].shareUrl;
      imageWidth = 1200;
      imageHeight = 630;
    }
    return (
      <Meta
        canonicalUrl={menuItem.url}
        description={description}
        imageHeight={imageHeight}
        imageUrl={imageUrl}
        imageWidth={imageWidth}
        title={`${menuItem.name} - ${menu.name}`}
      />
    );
  };

  if (loading) {
    return <LoadingPage />;
  }

  const { menuItem } = data ?? {};
  if (!menuItem) {
    return <NotFoundContainer />;
  }

  return (
    <div className={classNames('default-page-wrap', 'pm-dish-page-wrap')}>
      {renderMeta()}
      <MenuItemDetails
        isModal={false}
        loading={loading}
        menuItem={menuItem}
      />
    </div>
  );
};

MenuItemPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dishSlug: PropTypes.string,
    }),
  }).isRequired,
  restaurantId: PropTypes.number.isRequired,
};

export default compose(
  withRouter,
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
  })),
)(MenuItemPage);
